@import "../_variables.css";

.paginationControl {
    height: 44px;
    display: flex;
}

.paginationControl button {
    font-weight: bold;
    color: var(--color-dnv-brand-blue);
    background: none;
    border: none;
    cursor: pointer;
}

.paginationControl button:disabled {
    color: var(--color-disabled);
    cursor: default;
}

.paginationControl button span {
    margin-left: 1rem;
    margin-right: 1rem;
}

.paginationControl .currentPaginationPage {
    background: var(--color-dnv-interactive-blue);
    color: var(--color-white);
    width: 44px;
    text-align: center;
    display: table;
}

.paginationControl .currentPaginationPage span {
    display: table-cell;
    vertical-align: middle;
}

@media only screen and (max-width: 600px) {
    .paginationControl .firstPageBtn,
    .paginationControl .lastPageBtn {
        display: none;
    }

    .paginationControl .previousPageBtn {
        font-size: 12px;
        display: flex;
        margin-top: auto;
        margin-bottom: auto;
    }

    .paginationControl .nextPageBtn {
        font-size: 12px;
        display: flex;
        margin-top: auto;
        margin-bottom: auto;
    }
}