@import "../_variables.css";

.accountsPanelCardContainer {
    width: 100%;
    display: flex;
}
.accountsPanelCardContainer .cardTitle {
    font-weight: bold;
    font-family: var(--font-family-heading);
    font-size: var(--font-size-large);
    margin-bottom: 1rem;
}

.accountsPanelCardContainer .accountsLeftContainer {
    width: 50%;
    margin-right: 1rem;
}

.accountsPanelCardContainer .accountsRightContainer {
    width: 50%;
    margin-left: 1rem;
}

.accountsPanelCardContainer .accountsPersonalInfo {
    height: 100%;
}

.accountsPanelCardContainer .accountsPersonalInfo,
.accountsPanelCardContainer .accountsReset,
.accountsPanelCardContainer .accountsDelete {
    border: solid 2px var(--color-dnv-advisory-border-light-grey);
    background-color: var(--color-dnv-bg-and-border-pale-grey);
    padding: 1.5rem;
}

.accountsPanelCardContainer .accountsReset {
    margin-bottom: 2rem;
}


.accountsPanelCardContainer .accountsReset div:nth-child(2),
.accountsPanelCardContainer .accountsDelete div:nth-child(2) {
    font-weight: normal;
}

.accountsPanelCardContainer .entryField {
    margin-top: 1rem;
    position: relative;
}

.accountsPanelCardContainer .entryField label {
    font-weight: bold;
}

.accountsPanelCardContainer .entryField label input:disabled {
    padding-right: 2.5rem;
    background-color: var(--color-dnv-bg-and-border-beige-disabled);
}

.accountsPanelCardContainer .entryField span {
    position: absolute;
    right: 1rem;
    top: 2.25rem;
}

.accountsPanelCardContainer .entryField .emailNoEditMsg {
    font-weight: normal;
    font-style: italic;
    font-size: 16px;
}

.accountModalBtns button,
.accountsPanelCardContainer button {
    margin-top: 1rem;
    padding: 0.75rem 1.25rem;
    cursor: pointer;
    font-family: var(--font-family-paragraph);
    font-weight: bold;

    background: var(--color-white);
    border: solid 2px var(--color-dnv-interactive-blue);
    color: var(--color-dnv-interactive-blue);
}

.accountModalBtns button:hover,
.accountsPanelCardContainer button:hover {
    background: var(--color-dnv-interactive-blue);
    border: solid 2px var(--color-dnv-interactive-blue);
    color: var(--color-white);
}

.accountsPanelCardContainer .updateInfoBtn {
    margin-top: 2.5rem;
}

.accountModalBtns button.deleteBtn {
    margin-right: 1.5rem;
}

.accountModalBtns button.deleteBtn,
.accountsPanelCardContainer button.deleteBtn {
    border: solid 2px var(--color-dnv-messaging-red);
    background: var(--color-dnv-messaging-red);
    color: var(--color-dnv-text-white);
}

.accountModalBtns button.deleteBtn:hover,
.accountsPanelCardContainer button.deleteBtn:hover {
    border-color: #920d0d;
    background-color: #920d0d;
}