@import "../_variables.css";

.followingsPanelCard {
    width: 100%;
}

.followingsPanelCard .cardContainer {
    border: solid 2px #ebebeb;
    background-color: var(--color-dnv-bg-and-border-pale-grey);
    padding: 1.5rem;
}

.followingsPanelCard .cardTitle {
    font-weight: bold;
    font-family: var(--font-family-heading);
    font-size: var(--font-size-large);
}

.followingsPanelCard .followingsTableMobile {
    display: none;
}

.followingsPanelCard .followingsTable {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.followingsPanelCard .followingsTable .followingsTableHeader,
.followingsPanelCard .followingsTable .followingsTableDataRow {
    display: flex;
    width: 100%;
    padding: 1rem;
}

.followingsPanelCard .followingsTable .followingsTableHeader {
    border-bottom: solid 2px black;
    font-weight: bold;
    font-family: var(--font-family-paragraph);
}

.followingsPanelCard .followingsTable .followingsTableDataRow {
    border-bottom: solid 1px black;
}

.followingsPanelCard .followingsTable .followingsTableDataRow:nth-child(even) {
    background: var(--color-white);
}

.followingsPanelCard .followingsTable .positionCol {
    width: 96px;
}

.followingsPanelCard .followingsTable .PageCol {
    width: calc(100% - 96px - 120px - 48px);
}

.followingsPanelCard .followingsTable .DateUpdatedCol,
.followingsPanelCard .followingsTable .DateAddedCol {
    width: 120px;
}

.followingsPanelCard .followingsTable .extraActionsCol {
    width: 48px;
    text-align: right;
    position: relative;
}
.followingsPanelCard .followingsTable .extraActionsCol .openFollowingOptionsPanel {
    cursor: pointer;
    display: block;
}

.followingsPanelCard .followingOptions {
    display: none;
    position: absolute;
    min-width: 100px;
    width: max-content;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.51);
    z-index: 1;
    margin-top: 0.5rem;
    font-family: var(--font-family-paragraph);
    right: 24px;
    bottom: 0;
}

.followingsPanelCard .followingOptions button {
    color: var(--color-black);
    padding: 1rem;
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: right;
    border: none;
    background-color: var(--color-white);
}

.followingsPanelCard .followingOptions button span {
    margin-left: 1rem;
}

.followingsPanelCard .followingOptions button:hover:not(:disabled) {
    background-color: var(--color-dnv-bg-and-border-pale-grey);
    text-decoration: underline;
    color: var(--color-dnv-hover-green);
    cursor: pointer;
}

.followingsPanelCard .followingOptions.show {
    display: block;
}

.followingsPanelCard .followingOptions.show button:disabled {
    cursor: default;
    color: var(--color-disabled);
}

.followingsPanelCard .followingOptions.show button:disabled {
    background-color: var(--color-white);
    text-decoration: none;
}

.followingModalBtns {
    margin-top: 2rem;
}

.followingModalBtns button {
    padding: 0.75rem 1.25rem;
    cursor: pointer;
    font-family: var(--font-family-paragraph);
}

.followingModalBtns button:first-child {
    margin-right: 1rem;
}

.followingModalBtns .removeFollowingBtn {
    border: solid 1px var(--color-dnv-messaging-red);
    background: var(--color-dnv-messaging-red);
    color: var(--color-white);
}

.followingModalBtns .removeFollowingBtn:hover {
    background: #980303;
    border: solid 1px #980303;
}

.followingModalBtns .cancelFollowingBtn {
    background: var(--color-white);
    border: solid 1px var(--color-dnv-interactive-blue);
    color: var(--color-dnv-interactive-blue);
}

.followingModalBtns .cancelFollowingBtn:hover {
    background: var(--color-dnv-interactive-blue);
    border: solid 1px var(--color-dnv-interactive-blue);
    color: var(--color-white);
}

@media only screen and (max-width: 600px) {
    .followingsPanelCard .followingsTable {
        display: none;
    }

    .followingsPanelCard .followingsTableMobile {
        display: block;
        margin-top: 1rem;
    }

    .followingsPanelCard .followingsTableMobile .followingsTableDataRow {
        border: solid 1px black;
        padding: 0.75rem;
        font-size: 14px;
        margin-bottom: 1rem;
        background: var(--color-white);
    }

    .followingsPanelCard .followingsTableMobile .followingsTableDataRow > div {
        margin-bottom: 4px;
    }
    
    .followingsPanelCard .followingsTableMobile .followingsTableDataRow .followingRemoveBtn {
        margin-top: 1rem;
        margin-bottom: 0.75rem;
    }

    .followingsPanelCard .followingsTableMobile .followingsTableDataRow .followingRemoveBtn button {
        border: none;
        background: none;
        text-decoration: underline;
        padding: 0;
    }

    .followingsPanelCard .followingsTableMobile .followingsTableDataRow .followingRemoveBtn button span {
        margin-left: 0.5rem;
    }
}