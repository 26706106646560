/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

.last-modified {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 14px;
    padding-bottom: 22px;
    font-weight: 800;
    font-size: 17px;
    /* margin-top: -8px; */
    /* margin-top: 3rem; */
    /*color: #858585;*/
}

@media only screen and (max-width: 400px){
    .last-modified{
        padding-right: 10px;
        margin-top: -16px;
    }
}
