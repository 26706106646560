@import "../_variables.css";

.bookmarksPanelCard {
    width: 100%;
}

.bookmarksPanelCard .cardContainer {
    border: solid 2px #ebebeb;
    background-color: var(--color-dnv-bg-and-border-pale-grey);
    padding: 1.5rem;
}

.bookmarksPanelCard .cardTitle {
    font-weight: bold;
    font-family: var(--font-family-heading);
    font-size: var(--font-size-large);
}

.bookmarksPanelCard .bookmarksTableMobile {
    display: none;
}

.bookmarksPanelCard .bookmarksTable {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.bookmarksPanelCard .bookmarksTable .bookmarksTableHeader,
.bookmarksPanelCard .bookmarksTable .bookmarksTableDataRow {
    display: flex;
    width: 100%;
    padding: 1rem;
}

.bookmarksPanelCard .bookmarksTable .bookmarksTableHeader {
    border-bottom: solid 2px black;
    font-weight: bold;
    font-family: var(--font-family-paragraph);
}

.bookmarksPanelCard .bookmarksTable .bookmarksTableDataRow {
    border-bottom: solid 1px black;
}

.bookmarksPanelCard .bookmarksTable .bookmarksTableDataRow:nth-child(even) {
    background: var(--color-white);
}

.bookmarksPanelCard .bookmarksTable .positionCol {
    width: 96px;
}

.bookmarksPanelCard .bookmarksTable .PageCol {
    width: calc(100% - 96px - 120px - 48px);
}

.bookmarksPanelCard .bookmarksTable .DateAddedCol {
    width: 120px;
}

.bookmarksPanelCard .bookmarksTable .extraActionsCol {
    width: 48px;
    text-align: right;
    position: relative;
}
.bookmarksPanelCard .bookmarksTable .extraActionsCol .openBookmarkOptionsPanel {
    cursor: pointer;
    display: block;
}

.bookmarksPanelCard .bookmarkOptions {
    display: none;
    position: absolute;
    min-width: 200px;
    width: max-content;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.51);
    z-index: 1;
    margin-top: 0.5rem;
    font-family: var(--font-family-paragraph);
    right: 24px;
    bottom: 0;
}

.bookmarksPanelCard .bookmarkOptions button {
    color: var(--color-black);
    padding: 1rem;
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: right;
    border: none;
    background-color: var(--color-white);
}

.bookmarksPanelCard .bookmarkOptions button span {
    margin-left: 1rem;
}

.bookmarksPanelCard .bookmarkOptions button:hover:not(:disabled) {
    background-color: var(--color-dnv-bg-and-border-pale-grey);
    text-decoration: underline;
    color: var(--color-dnv-hover-green);
    cursor: pointer;
}

.bookmarksPanelCard .bookmarkOptions.show {
    display: block;
}

.bookmarksPanelCard .bookmarkOptions.show button:disabled {
    cursor: default;
    color: var(--color-disabled);
}

.bookmarksPanelCard .bookmarkOptions.show button:disabled {
    background-color: var(--color-white);
    text-decoration: none;
}

.bookmarkModalBtns {
    margin-top: 2rem;
}

.bookmarkModalBtns button {
    padding: 0.75rem 1.25rem;
    cursor: pointer;
    font-family: var(--font-family-paragraph);
}

.bookmarkModalBtns button:first-child {
    margin-right: 1rem;
}

.bookmarkModalBtns .removeBookmarkBtn {
    border: solid 1px var(--color-dnv-messaging-red);
    background: var(--color-dnv-messaging-red);
    color: var(--color-white);
}

.bookmarkModalBtns .removeBookmarkBtn:hover {
    background: #980303;
    border: solid 1px #980303;
}

.bookmarkModalBtns .cancelBookmarkBtn {
    background: var(--color-white);
    border: solid 1px var(--color-dnv-interactive-blue);
    color: var(--color-dnv-interactive-blue);
}

.bookmarkModalBtns .cancelBookmarkBtn:hover {
    background: var(--color-dnv-interactive-blue);
    border: solid 1px var(--color-dnv-interactive-blue);
    color: var(--color-white);
}

@media only screen and (max-width: 600px) {
    .bookmarksPanelCard .bookmarksTable {
        display: none;
    }

    .bookmarksPanelCard .bookmarksTableMobile {
        display: block;
        margin-top: 1rem;
    }

    .bookmarksPanelCard .bookmarksTableMobile .bookmarksTableDataRow {
        border: solid 1px black;
        padding: 0.75rem;
        font-size: 14px;
        margin-bottom: 1rem;
        background: var(--color-white);
    }

    .bookmarksPanelCard .bookmarksTableMobile .bookmarksTableDataRow > div {
        margin-bottom: 4px;
    }
    
    .bookmarksPanelCard .bookmarksTableMobile .bookmarksTableDataRow .bookmarkBtns {
        margin-top: 1rem;
    }
    
    .bookmarksPanelCard .bookmarksTableMobile .bookmarksTableDataRow .bookmarkBtns > div {
        margin-bottom: 0.75rem;
    }

    .bookmarksPanelCard .bookmarksTableMobile .bookmarksTableDataRow .bookmarkBtns button {
        border: none;
        background: none;
        text-decoration: underline;
        padding: 0;
    }

    .bookmarksPanelCard .bookmarksTableMobile .bookmarksTableDataRow .bookmarkBtns button span {
        margin-left: 0.5rem;
    }

    .bookmarkModalBtns button:first-child {
        margin-bottom: 1rem;
    }
}