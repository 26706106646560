.rateMyExperience{
    margin-top: 64px;
    border: solid 2px var(--color-dnv-brand-grey);
    padding: 20px 30px;
}

.rateMyExperience h2{
    margin-bottom: 15px;
}

.rateMyExperience p{
    margin-bottom: 11px;
}

.rateMyExperience .bold{
    font-weight: 800;
}

.rateMyExperience textarea{
    margin-top: 6px;
    height: 100px;
}

.rateMyExperience .btn{
    padding: 14px 20px;
    width: 175px;
    height: 48px;
    margin-top: 30px;
    margin-bottom: 2px;
    font-size: 17px;
    display: block;
}

.rateMyExperience .btn:disabled{
    background-color: var(--color-radio-disabled);
    color: var(--color-black);
}

.experience-emojis {
    display: flex;
    gap: 16px;
    margin-bottom: 22px;
}

.layout .experience-emojis input[type=radio]{
    position: static
}

.experience-emojis .label-wrapper{
    text-align: center;
    margin: 0px 4px;
}

.experience-emojis .label-wrapper label{
    font-size: 22px;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border: solid 2px var(--color-dnv-brand-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}

.experience-emojis .label-wrapper label.selected{
    background-color: var(--color-dnv-brand-blue);
}

.experience-emojis .label-wrapper label:hover{
    cursor: pointer;
}

.rateMyExperience .submitted{
    padding-top: 3px;
}

.rateMyExperience .submitted svg{
    width: 48px;
    height: 48px;
    color: var(--color-dnv-brand-green);
}

.rateMyExperience .submitted.error svg{
    color: var(--color-dnv-messaging-red);
}

.rateMyExperience .submitted p{
    margin-top: 11px;
}