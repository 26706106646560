@import "../_variables.css";

label.switch > input[type=checkbox] {
  opacity: 0;
  display: none;
}

label.switch > div.switch-btn {
  position: relative;
  width: 52px;
  height: 30px;
  background: var(--color-dnv-brand-grey);
  border-radius: 25px;
  box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.15);
}

label.switch > div.switch-btn:before {
  content: "";
  position: absolute;
  height: 24px;
  width: 24px;
  background: var(--color-dnv-interactive-white);
  left: 2px;
  top: 50%;
  transition: all 200ms ease-out;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 8px 6px -4px rgba(0, 0, 0, 0.1);
  transform: translateY(-50%);
}

label.switch > input[type=checkbox]:checked + div.switch-btn {
  background: var(--color-dnv-brand-green);
}

label.switch > input[type=checkbox]:checked + div.switch-btn:before {
  left: 26px;
}
  