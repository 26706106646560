@import "../_variables.css";

.subscriptionToggleCards {
    width: 100%;
}

.subscriptionToggleCards .cardRow {
    display: flex;
    margin-bottom: 2rem;
    justify-content: space-between;
    gap: 32px;
}

.subscriptionToggleCards .cardContainer {
    border: solid 2px #ebebeb;
    background-color: var(--color-dnv-bg-and-border-pale-grey);
    padding: 1.5rem;
}

.subscriptionToggleCards .smallToggleCards .cardRow .cardContainer {
    /* margin-right: 1rem;
    width: calc(50% - 1rem); */
    width: 50%;
}

/* .subscriptionToggleCards .smallToggleCards .cardRow .cardContainer:last-child {
    margin-left: 1rem;
    width: calc(50% - 1rem);
} */

.subscriptionToggleCards .largeToggleCards .cardRow .cardContainer {
    width: 100%;
}

.subscriptionToggleCards .largeToggleCards .cardRow:last-child {
    margin-bottom: 0;
}

.subscriptionToggleCards .cardContainer .cardTitle {
    font-weight: bold;
    font-family: var(--font-family-heading);
    font-size: var(--font-size-large);
}

.subscriptionToggleCards .cardContainer .cardSwitchAndDesc {
    margin-top: 1rem;
    display: flex;
    align-items: center;
}

.subscriptionToggleCards .cardContainer .cardSwitchAndDesc .description {
    margin-left: 1rem;
    font-weight: bold;
}

.subscriptionToggleCards .cardContainer.alerts .cardSwitchAndDesc .description{
    margin-left: 0px;
}

.subscriptionToggleCards .cardContainer .cardToggledMessageContainer {
    margin-top: 1rem;
    border-top: solid 1px black;
    padding-top: 2rem;
}

.subscriptionToggleCards .cardContainer .cardToggledMessageContainer .message {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.subscriptionToggleCards .cardContainer .cardToggledMessageContainer .message svg {
    color: var(--color-dnv-brand-green);
    width: 1.25rem;
    height: 1.25rem;
}

.subscriptionToggleCards .cardContainer .cardToggledMessageContainer .message .text {
    margin-left: 0.5rem;
}

.subscriptionToggleCards .cardContainer .cardChildComponent .devApplicationSubscriptionsList .selectedDevApplicationsOptionsList {
    margin-bottom: 1rem;
}

.subscriptionToggleCards .cardContainer .cardChildComponent .devApplicationSubscriptionsList .selectedDevApplicationsOptionsList .selectedOption {
    padding: 1rem;
    color: var(--color-dnv-brand-blue);
    background-color: var(--color-dnv-bg-and-border-pale-tan);
    margin-right: 1rem;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 1rem;
}

.subscriptionToggleCards .cardContainer .cardChildComponent .openDevApplicationListModalBtn button {
    text-decoration: underline;
    font-weight: bold;
    color: var(--color-dnv-brand-blue);
    background: none;
    border: none;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .subscriptionToggleCards .cardRow {
        display: block;
        margin-bottom: 2rem;
    }

    .subscriptionToggleCards .cardRow .cardContainer {
        margin: 0 0 2rem 0 !important;
        width: auto !important;
    }
}