.modal-overlay {
    background: rgba(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
  }
  
.modal {
    margin: 15px;
    background-color: white;
    padding: 16px 48px 48px 48px;
    width: 757px;
    height: 297px;
}
  
.tall-modal {
    margin: 15px;
    background-color: white;
    padding: 16px 48px 48px 48px;
    width: 757px;
    height: 397px;
}

.modal h2{
    font-weight: 750;
    margin-top: 13px;
    margin-bottom: 18px;
}


.close-btn-wrapper{
    display: flex;
    justify-content: flex-end;
}

.modal-close{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: none;
    color: var(--color-dnv-brand-blue);
    width: 20px;
    height: 20px;
    margin-right: 3px;
}

.modal-close:hover{
    color: var(--color-dnv-focus-dark-green);
    cursor:pointer;
}

.modal .btn{
    margin: 0px;
}

.btn.outline{
    padding: 9px 14px;
    border: solid 2px var(--color-dnv-interactive-blue);
    height: 48px;
    background-color: transparent;
    color: var(--color-dnv-interactive-blue);
    transition: none;
}

.btn.outline:hover{
    color: var(--color-white);
    background-color: var(--color-dnv-hover-green);
    cursor: pointer;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.51);
    border-color: var(--color-dnv-hover-green);
}

.btn.btn-link{
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: var(--color-dnv-brand-blue);
    padding: 0;
    font-family: var(--font-family-links-labels);
    font-weight: 700;
    font-size: 20px;
    line-height: 37px;
    text-decoration: underline;
    text-align: left;
    margin: 2px 0px -1px 0px;
}

.modal .button-wrapper{
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    align-items: center;
}

.modal .button-wrapper > .btn{
    padding: 14px 20px;
    font-weight: 800;
    height: 48px;
}

.tall-modal.verify-email{
    height: auto;
    min-height: 516px;
}
.tall-modal.verify-email .button-wrapper .btn-link{
    font-size: 17px;
}

.tall-modal.verify-email ul{
    list-style: disc;
    margin-left: 24px;
    margin-bottom: 1.5em;
}

.tall-modal.verify-email .email-address{
    background-color: var(--color-dnv-bg-and-border-beige-disabled);
    border: solid 1px var(--color-dnv-brand-grey);
    padding: 11px;
    border-radius: 3px;
    margin-bottom: 1.5em;
}

.tall-modal.verify-email .email-address svg{
    margin-right: 10px;
}

.tall-modal.verify-email h2{
    margin-bottom: 17px;
    margin-top: 15px;
}

.tall-modal.verify-email .expired{
    color: var(--color-dnv-messaging-red);
    font-size: 90px;
    line-height: 90px;
    margin-bottom: 21px;
}

.tall-modal.verify-email .verified{
    color: var(--color-dnv-messaging-green);
    font-size: 90px;
    line-height: 90px;
    line-height: 90px;
    margin-bottom: 21px;
}

.tall-modal.verify-email .waiting{
    margin-bottom: 30px
}

.tall-modal.verify-email .btn.continue{
    width: 113px;
    padding: 16px 0px;
}

.tall-modal.verify-email .btn.resend{
    width: 240px;
    padding: 16px 0px;
}

@media (max-width: 775px) {
    .modal{
        width: 90%;
    }
}