@import "../_variables.css";

.myDnvDevAppSubscriptions .optionsList .optionEntry:not(:first-child) {
    margin-top: 0.5rem;
}

.myDnvDevAppSubscriptions .optionsList .optionEntry label {
    display: flex;
    align-items: center;
}

.myDnvDevAppSubscriptions .optionsList .optionEntry input {
    margin-right: 0.5rem;
    accent-color: var(--color-dnv-brand-green);
    height: 1rem;
    width: 1rem;
}

.myDnvDevAppSubscriptions .infoContainer {
    padding: 1.25rem;
    border: solid 1px black;
    margin-top: 2rem;
    display: flex;
    align-items: center;
}

.myDnvDevAppSubscriptions .infoIcon {
    position: relative;
    height: 40px;
    width: 40px;
}

.myDnvDevAppSubscriptions .infoIcon > .rectangle {
    width: 4rem;
    height: 3rem;
    background-color: var(--color-dnv-brand-navy);
    border-radius: 5px;
}

.myDnvDevAppSubscriptions .infoIcon > svg {
    position: absolute;
    color: white;
    top: 25%;
    left: 1.75rem;
    font-size: 24px;
}

.myDnvDevAppSubscriptions .infoIcon > .rotatedTriangle {
    width: 1rem;
    aspect-ratio: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    background: var(--color-dnv-brand-navy);
    position: absolute;
    top: calc(3rem - 1px);
    left: 2rem;
}

.myDnvDevAppSubscriptions .infoIcon > .iconLayerBlock {
    height: 40px;
    width: 40px;
    position: relative;
}

.myDnvDevAppSubscriptions .infoIcon > .iconLayerBlock > svg:first-child {
    width: 100%;
    height: 100%;
    color: var(--color-dnv-brand-navy);
    transform: rotateY(180deg);
}

.myDnvDevAppSubscriptions .infoIcon > .iconLayerBlock > svg:last-child {
    color: var(--color-dnv-text-white);
    position: absolute;
    top: -5px;
    left: 0px;
}

.myDnvDevAppSubscriptions .infoMsg {
    margin-left: 1rem;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bold;
}

.myDnvDevAppSubscriptions .buttonsContainer {
    margin-top: 2rem;
    display: flex;
    font-family: var(--font-family-links-labels);
}

.myDnvDevAppSubscriptions .buttonsContainer > .saveBtn {
    padding: 1rem 1.5rem;
    background-color: var(--color-dnv-brand-green);
    color: var(--color-dnv-text-white);
    margin-right: 1.5rem;
    cursor: pointer;
    border: none;
    display: table;
}

.myDnvDevAppSubscriptions .buttonsContainer > .saveBtn:hover {
    background-color: var(--color-dnv-interactive-blue);
}

.myDnvDevAppSubscriptions .buttonsContainer > .cancelBtn {
    padding: 0.75rem 1.5rem;
    background-color: var(--color-dnv-text-white);
    color: var(--color-dnv-brand-blue);
    cursor: pointer;
    border: solid 2px var(--color-dnv-brand-blue);
    display: table;
}

.myDnvDevAppSubscriptions .buttonsContainer > .cancelBtn:hover {
    background-color: var(--color-dnv-brand-blue);
    color: var(--color-dnv-text-white);
}


.myDnvDevAppSubscriptions .buttonsContainer span {
    display: table-cell;
    vertical-align: middle;
}