/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
:root {
    --cta-card-size: 345px;
    --cta-card-size-height: 345px;
    --cta-card-max-width: 900px;
    --cta-card-half-width: 50%;
    --cta-image-height-vertical: 194px;
    --cta-max-height-vertical: 320px;
}
/* .simplicity-cta-container .rich-text-container{
   margin-bottom: var(--spacing-y);
   display: -webkit-box;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
} */
.simplicity-cta-container{
    border: solid 1px var(--color-dnv-advisory-border-light-grey);
}

.simplicity-cta-container:hover {
    border: 1px solid var(--color-dnv-top-bar-border-light-blue);
}
.simplicity-cta-container .rich-text-container.truncate {
   /* -webkit-line-clamp: 4; */
   padding: 32px;
}
.simplicity-cta-container .rich-text-container.truncate + .cta-link-item >.simplicity-text-link-item{
    margin-bottom: 32px;
}
.simplicity-cta-container .rich-text-container.overflow {
   /* -webkit-line-clamp: 6; */
   padding: 32px;
}

.simplicity-cta-container a {
    font-family: var(--font-family-links-labels);
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
}
.simplicity-cta-container a:hover{
    color: var(--color-dnv-focus-dark-green);
}
.simplicity-cta-container.horizontal {
    /*display: flex;*/
    display: table;
    flex-direction: row;
    height: var(--cta-card-size-height);
    max-height: var(--cta-card-size-height);
    /* max-width: var(--cta-card-max-width); */
    /* max-width: 1060px; */
    /* border-radius: 4px; */
    background-color: var(--color-dnv-bg-and-border-pale-grey);
}
.simplicity-cta-content-image.horizontal {
    display: table-cell;
    min-width: var(--cta-card-size);
    width: var(--cta-card-half-width);
    /* height: var(--cta-card-size-height); */
    height: 368px;
    background-color: var(--color-dnv-bg-and-border-grey);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    overflow: hidden;
}
.simplicity-cta-content.horizontal {
    padding: 1.875rem 1.875rem 1.875rem 1.85rem;
    vertical-align: top;
    text-align: left;
    display: table-cell;
    height: var(--cta-card-size-height);
    min-width: var(--cta-card-size);
}
.simplicity-cta-content.horizontal .cta-title {
    color: var(--color-dnv-text-black);
    font-family: var(--font-family-heading);
    font-weight: 700;
    font-size: 25px;
    margin: 0 auto 16px;
}
.simplicity-cta-content.horizontal .cta-title a{
    font-size: 20px;
}
.simplicity-cta-content.horizontal div.tiptap {
    padding: 0 0 0 0;
}

.simplicity-cta-container.horizontal .cta-link-container ul > li  a {
    color: var(--color-dnv-interactive-blue);
    fill: var(--color-dnv-interactive-blue);
}

/*-------------------------------------------*/

.simplicity-cta-container.vertical {
    display: flex;
    flex-direction: column;
    /* max-width: var(--cta-card-size); */
    width: 100%;
    height: auto;
    border-radius: 4px;
    position: relative;
    /* max-height: 676px; */
}
.simplicity-cta-container.vertical .cta-link-container a,
.simplicity-cta-container.vertical .cta-link-container a i:before,
.simplicity-cta-container.vertical .cta-link-container svg path {
    color: var(--color-dnv-interactive-dark-blue);
    fill: var(--color-dnv-interactive-dark-blue);
}
.simplicity-cta-container.vertical .cta-link-container a:hover,
.simplicity-cta-container.vertical .cta-link-container a:hover i:before,
.simplicity-cta-container.vertical .cta-link-container svg path {
    color: var(--color-dnv-hover-dark-green);
    fill: var(--color-dnv-hover-dark-green);
}
.simplicity-cta-container.vertical .cta-link-container a:active,
.simplicity-cta-container.vertical .cta-link-container a:active i:before,
.simplicity-cta-container.vertical .cta-link-container svg path {
    color: var(--color-dnv-active-black);
    fill: var(--color-dnv-active-black);
}
.simplicity-cta-content-image.vertical {
    /* max-width: var(--cta-card-size); */
    width: auto;
    height: var(--cta-image-height-vertical);
    top: 0;
    left: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-indent: -99999px;
    text-align: left;
    overflow: hidden;
}
.simplicity-cta-content.vertical {
    /* padding: 0px 0px 1.875rem 0px; */
    vertical-align: top;
    text-align: left;
    height: auto;
    width: 100%;
    min-height: var(--cta-max-height-vertical);
}
.simplicity-cta-content.vertical .cta-title {
    color: var(--color-white);
    font-family: var(--font-family-heading);
    background-color: var(--color-dnv-bg-and-border-navy);
    font-weight: 700;
    font-size: 25px;
    /* margin: 0 auto 24px; */
    height: auto;
    padding: 12px 24px;
}
.simplicity-cta-content.vertical div.tiptap {
    padding: 0 0 0 0;
}

.simplicity-cta-content .rich-text-container p {
    margin-bottom: 24px;
}
.simplicity-cta-content .rich-text-container p:last-child{
    margin-bottom: 0px;
}
.simplicity-cta-container {
    display: flex;
    flex-direction: column;
}
.cta-link-container {
    /* padding: 4px 0; */
    display: flex;
    flex-direction: column;
    /* max-height: 80px; */
    justify-content: space-evenly;
}
.cta-link-item {
    margin: 2px 0;
    /* max-height: 22px; */
    padding: 0px 32px;
}

.cta-link-item .simplicity-link-icon{
    margin-left: 5px;
}
.cta-link-container .text-link {
    text-decoration: none;
}
.cta-link-container .underline-link {
    text-decoration: underline;
}
.cta-link-container .cta-link {}
.cta-link-container .bullet-point-link {}

.cta-link-container .cta-link-item {
    padding: 0px;
}
.cta-link-container .cta-link-item .simplicity-text-link-item:has(.body){
    margin: 0px 0px 16px 0px;
}

.two-column-side-nav-left .component.call-to-action{
    min-width: 336px;
    margin-left: 24px;
}

.dev-apps-cta{
    margin-top: 60px;
}

@media only screen and (max-width: 1124px) {
    .simplicity-cta-container.vertical{
        /* max-height: var(--cta-card-size-height); */
        flex-direction: row;
        display: table-row;
    }
    .simplicity-cta-container.vertical:nth-last-child(1){
        width: 100%;
    }

    .simplicity-cta-content.vertical{
        width: 50%;
        display: table-cell;
    }
    .simplicity-cta-content-image.vertical{
        width: 50%;
        height: var(--cta-card-size-height);
        display: table-cell;
    }

    .two-column-side-nav-left .sidebar{
        width: auto;
    }
}

@media (max-width: 775px) {
    .simplicity-cta-container.horizontal {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        height: auto;
        min-height: 585px;
        max-height: 100%;
    }
    .simplicity-cta-content-image.horizontal {
        width: 100%;
        height: 240px;
        object-fit: cover;
    }
    .simplicity-cta-content.horizontal {
        position: relative;
        width: unset;
        height: auto;
        min-width: unset;
    }

    .simplicity-cta-container.vertical {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
    }
    .simplicity-cta-content-image.vertical {
        position: relative;
        width: 100%;
        height: 200px;
    }
    .simplicity-cta-content.vertical {
        position: relative;
        width: 100%;
        height: auto;
    }
    .simplicity-cta-content-image {
        min-width: unset;
        width: 100%
    }
}

@media only screen and (max-width: 377px) {
    .simplicity-cta-content-image.horizontal{
        min-width: unset;
    }
}

@media only screen and (max-width: 344px) {
    .two-column-side-nav-left .component.call-to-action{
        min-width: unset;
        margin-left: 0px;
        margin-top: 0px;
    }
}

@media only screen and (min-width: 345px) {
    .two-column-side-nav-left .component.call-to-action{
        min-width: 313px;
        margin: 0px 16px 24px 0px;
    }

}
