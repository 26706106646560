/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.simplicity-breadcrumb {
    list-style: none;
    margin-bottom: 32px;
}

.simplicity-breadcrumb > ul {
    /*display: flex;*/
    /*flex-wrap: wrap;*/
    /*padding-left: 0;*/
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    /*gap: 10px 44px;*/
    /*gap: 20px;*/
    flex-wrap: wrap;
    max-width: unset;
}

.simplicity-breadcrumb > ul > li {
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 !important;
}

.simplicity-breadcrumb > ul > li:not(:last-child) {
    position: relative;
}

/*.simplicity-breadcrumb > ul > li:not(:last-child):after {*/
/*    position: absolute;*/
/*    font-family: 'Font Awesome 6 Pro';*/
/*    content: '\f054';*/
/*    left: calc(100% + 16.375px);*/
/*    color: #919191;*/
/*    transform: translateY(-50%);*/
/*    top: 50%;*/
/*    font-size: 18px;*/
/*}*/

.simplicity-breadcrumb > ul > li > a {
    color: var(--color-dnv-interactive-blue);
    font-weight: 700;
    text-decoration: underline;
    /*font-family: "Avenir LT W01_85 Heavy1475544","Helvetica Neue",Arial,sans-serif;*/
    font-family: var(--font-family-links-labels);
    font-size: 17px;
    line-height: 22px;
}

.simplicity-breadcrumb > ul > li > a:hover {
    color: var(--color-dnv-hover-green);
}

.simplicity-breadcrumb-item svg {
    margin-left: 18px;
    margin-right: 18px;
    /*display: none;*/
    /* margin: 0 1em;
    color: var(--color-border-grey); */
}
.simplicity-breadcrumb-item i {
    display: none;
    /* margin: 0 1em;
    color: var(--color-border-grey); */
}

@media only screen and (max-width: 48rem) {
    /* .simplicity-breadcrumb > ul {
        gap: 10px 32px;
    } */
    .simplicity-breadcrumb > ul > li:not(:last-child):after {
        left: calc(100% + 10.375px);
    }
  }
