@import url("../_variables.css");

.myDnvLoginButtonDropdown {
    position: relative;
    margin-top: -5px;
}

.myDnvLoginButtonDropdown > a.signedIn{
    margin-top: 5px;
}

.myDnvLoginButtonDropdown > button,
.myDnvLoginButtonDropdown > a {
    background-color: var(--color-dnv-brand-green);
    color: var(--color-white);
    font-family: var(--font-family-links-labels);
    border: none;
    padding: 0rem 1rem;
    height: 48px;
}

.myDnvLoginButtonDropdown > a > .MuiCircularProgress-root,
.top-bar-content > ul.myDNV a {
    color: var(--color-white);
}

.myDnvLoginButtonDropdown > a:hover,
.myDnvLoginButtonDropdown > button:hover {
    cursor: pointer;
    background-color: var(--color-dnv-interactive-blue);
}

.myDnvLoginButtonDropdown > button:focus {
    border: none;
}

.myDnvLoginButtonDropdown > button.dropdownActive {
    background-color: var(--color-dnv-interactive-blue);
}

.myDnvLoginButtonDropdown > button > span {
    margin-left: 5px;
}

.myDnvLoginButtonDropdown > button > span:hover {
    cursor: pointer;
}

.myDnvLoginButtonDropdown .dropdownBtnContent {
    display: none;
    position: absolute;
    min-width: 190px;
    overflow: auto;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.51);
    z-index: 1;
    margin-top: 0.5rem;
    font-family: var(--font-family-links-labels);
    right: 0;
}

.myDnvLoginButtonDropdown .dropdownBtnContent button {
    color: var(--color-dnv-interactive-blue);
    padding: 1rem;
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: left;
    border: none;
    background-color: var(--color-white);
}

.myDnvLoginButtonDropdown .dropdownBtnContent button:hover {
    background-color: var(--color-dnv-bg-and-border-pale-grey);
    text-decoration: underline;
    color: var(--color-dnv-hover-green);
    cursor: pointer;
}

.myDnvLoginButtonDropdown .dropdownBtnContent.show {
    display: block;
}