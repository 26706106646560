@import '../_variables.css';

.main-content:has(.myDNVPageManagementBar) > h1 {
    margin-bottom: 1rem;
}

.myDNVPageManagementBar {
    /* display: block;
    text-align: right; */
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: solid 1px var(--color-black);
}

.myDNVPageManagementBar .last-modified{
    width: auto;
    padding-bottom: 0px;
    margin-top: 7px;
}

.myDNVPageManagementBar .myDNVPageManagementButtonContainer {
    display: flex;
    gap: 16px;
}

.myDNVPageManagementBar .myDNVPageManagementButtonContainer button {
    border: 2px solid #e5e5e5;
    background-color: var(--color-dnv-bg-and-border-pale-grey);
    color: var(--color-dnv-brand-navy);
    cursor: pointer;
    padding: 6px;
    border-radius: 4px;
    font-weight: bold;
    height: 34px;
}

.myDNVPageManagementBar .myDNVPageManagementButtonContainer button:hover{
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.51);
}

.myDNVPageManagementBar .myDNVPageManagementButtonContainer button.share-button{
    padding: 4px 10px;
    display: flex;
    gap: 6px;
}

.myDNVPageManagementBar .myDNVPageManagementButtonContainer button.subscribed,
.myDNVPageManagementBar .myDNVPageManagementButtonContainer button.bookmarked {
    border-color: var(--color-dnv-brand-navy);
}

.myDNVPageManagementBar .myDNVPageManagementButtonContainer button span {
    margin-right: 0.5rem;
    font-weight: bold;
}

