@import "../_variables.css";

.keywordSearchContainer {
    margin-top: 1rem;
}

.keywordSearchContainer .searchTitle {
    font-weight: bold;
    font-family: var(--font-family-paragraph);
}

.keywordSearchContainer .searchBar {
    display: flex;
    margin-top: 0.5rem;
    position: relative;
}

.keywordSearchContainer .searchBar input {
    width: 290px;
    margin-right: 1rem;
    padding-right: 2rem;
}

.keywordSearchContainer .searchBar span {
    position: absolute;
    cursor: pointer;
    left: calc(290px - 1.5rem);
    top: 25%;
}

.keywordSearchContainer .searchBar button {
    background: var(--color-dnv-interactive-blue);
    color: var(--color-white);
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.keywordSearchContainerDisplayCount {
    margin-top: 1rem;
}

@media only screen and (max-width: 600px) {
    .keywordSearchContainer .searchBar {
        display: block !important;
    }

    .keywordSearchContainer .searchBar input {
        margin-bottom: 1rem !important;
    }

    .keywordSearchContainer .searchBar span {
        left: calc(100% - 1.5rem) !important;
        top: 0.825rem !important;
    }
}