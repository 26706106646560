
/* top bar*/
.btn:not(.icon).btn-bookmark{
    width: 48px;
    height: 48px;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 10px 0px;
     background-color: var(--color-white);
     color: var(--color-dnv-interactive-blue);
     border: solid 2px var(--color-dnv-interactive-blue);
 }

 .btn:not(.icon).btn-bookmark:hover{
    background-color: var(--color-dnv-hover-green);
    border-color: var(--color-dnv-hover-green);
    color: var(--color-white);
 }

 .btn:not(.icon).btn-bookmark:active{
    background-color: var(--color-black);
    border-color: var(--color-black);
    color: var(--color-white);
 }

 ul.myDNV{
    margin-top: 5px;
    margin-left: 0px;
 }

 /* layout / content */
.layout.myDNV .layout-fullwidth{
    padding: 56px 32px;
}

.layout.myDNV .panel-group .tab-nav-wrapper .tab-nav li{
    padding: 14px 20px;
    margin-right: 8px;
}

.layout.myDNV .panel-group .tab-nav-wrapper .tab-nav li.active{
    border-bottom-color: var(--color-white);
    position: relative;
    top: 0px;
}

/* bookmarks side pane */
.slide-pane.myDNV-bookmarks-pane{
    padding: 52px 48px;
} 

.slide-pane.myDNV-bookmarks-pane .slide-pane__close{
    padding: 0px;
    margin-right: -6px;
    margin-top: -2px;
}

.slide-pane.myDNV-bookmarks-pane .slide-pane__header{
    flex: none;
    margin-top: -7px;
    margin-bottom: 21px;
}

.slide-pane.myDNV-bookmarks-pane .slide-pane__content table{
    width: 100%;
}

.slide-pane.myDNV-bookmarks-pane .slide-pane__content .table-wrapper{
    max-height: calc(100% - 100px);
    overflow-y: auto;
    height: auto;
}

.slide-pane.myDNV-bookmarks-pane .slide-pane__content table thead tr th{
    border-bottom: solid 2px #1a1a1a;
    padding: 9px 16px 12px 16px;
    font-weight: 800;
    background-color: var(--color-white);
}
.slide-pane.myDNV-bookmarks-pane .slide-pane__content table td{
    padding: 12px 16px;
    border-bottom: solid 1px var(--color-dnv-brand-grey);
}

.slide-pane.myDNV-bookmarks-pane .slide-pane__content table tr:nth-child(odd){
    background-color: var(--color-dnv-bg-and-border-pale-grey);
}

.slide-pane.myDNV-bookmarks-pane .slide-pane__content .manage-bookmarks{
    position: absolute;
    bottom: 56px;
}

@media only screen and (max-width: 600px) {
    .layout.myDNV .panel-group .tab-nav-wrapper .tab-nav li {
        margin: 0;
    }
}