@import '../_variables.css';

.subscribeBannerContainer {
    display: block;
    border-top: solid 1px var(--color-black);
    border-right: solid 1px var(--color-black);
    border-bottom: solid 1px var(--color-black);
    margin-bottom: 2rem;
}

.subscribeBannerContainer .bannerContent {
    display: flex;
    width: 100%;   
}

.subscribeBannerContainer .bannerContent .colorBar {
    width: 0.5rem;
    margin-right: 1rem;
}

.subscribeBannerContainer .bannerContent .colorBar.subscribed {
    background: var(--color-dnv-messaging-green);
}

.subscribeBannerContainer .bannerContent .colorBar.unsubscribed {
    background: var(--color-dnv-messaging-dark-yellow);
}

.subscribeBannerContainer .bannerContent .bannerInfo {
    padding: 1rem 0.5rem;
    display: flex;
}

.subscribeBannerContainer .bannerContent .bannerInfo .bannerIconCol {
    margin-right: 1rem;
}

.subscribeBannerContainer .bannerContent .bannerInfo .bannerIconCol.subscribed svg {
    color: var(--color-dnv-messaging-green);
}

.subscribeBannerContainer .bannerContent .bannerInfo .bannerIconCol.unsubscribed svg {
    color: var(--color-dnv-messaging-dark-yellow);
}

.subscribeBannerContainer .bannerContent .bannerInfo .bannerTextCol .bannerTitle {
    font-family: var(--font-family-forms-helper);
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.subscribeBannerContainer .bannerContent .bannerInfo .bannerTextCol .bannerMsg {
    margin-bottom: 1rem;
}