@import "../_variables.css";

.MuiModal-root {
    z-index: 10000 !important;
}

.MuiBox-root {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45%;
    background-color: var(--color-white);
    border: 2px solid var(--color-black);
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
    padding: 3rem;
}

.closeModalBtn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: var(--color-dnv-interactive-blue);
    float: right;
    cursor: pointer;
    font-weight: bold;
    font-family: var(--font-family-paragraph);
    font-size: var(--font-size-medium);
}

#modal-modal-title {
    font-family: var(--font-family-heading);
	font-size: var(--font-size-large);
}

#modal-modal-description {
    font-family: var(--font-family-paragraph);
    font-size: var(--font-size-medium);
    margin-top: 2rem;
}

@media only screen and (max-width: 600px) {
    .MuiModal-root .MuiBox-root {
        top: 50%;
        left: 50%;
        width: 90%;
        padding: 2rem;
    }
}