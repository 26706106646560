.logout-background{
    background-color: var(--color-dnv-brand-navy);
    display: flex;
    justify-content: space-between;
    color: var(--color-white);
    max-width: var(--desktop-width);
    width: 100%;
    margin: 0px auto;
    padding: 16px 32px;
}

.logout-background .mydnv-title{
    font-weight: 800;
    line-height: 22px;
    border-bottom: 3px solid var(--color-dnv-active-bright-yellow);
    padding-bottom: 9px;
    display: flex;
    align-items: center;
    width: 91px;
    height: 31px;
}

.logout-background .mydnv-title svg{
    width: 20px;
    height: 22px;
    margin-right: 9px;
    margin-top: 3px;
}

.logout-background .btn-logout{
    border: none;
    background-color: transparent;
    color: var(--color-white);
    padding: 0px;
    width: 90px;
    height: 31px;
    display: flex;
    align-items: center;
    margin-top: -5px;
    border-bottom: solid 3px var(--color-dnv-brand-navy);
}

.logout-background .btn-logout:hover{
    border-bottom: solid 3px var(--color-dnv-hover-bright-yellow);
    color: var(--color-dnv-hover-bright-yellow);
}

.logout-background .btn-logout:active{
    border-bottom: solid 3px var(--color-dnv-active-bright-blue);
    color: var(--color-dnv-active-bright-blue);
}

.logout-background .btn-logout svg{
    margin-right: 8px;
    width: 20px;
    height: 20px;
}